import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UISelectors from 'selectors/UISelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import { useOktaAuth } from '@okta/okta-react';
import UIActions from 'actions/UIActions';
import DataCollectionLandingContent from 'components/DataCollectionLandingContent/DataCollectionLandingContent';
import Spinner from 'components/Spinner/Spinner';
import View from 'components/View/View';
import { EVENTS, logEvent } from 'lib/amplitude';
import LocalStorageProxy from 'lib/localStorageProxy';
import { signOut } from 'lib/okta';
import { injectIntl } from 'react-intl';
import { ModalProvider } from 'contexts/ModalContext';
import dataCollectionActions from 'actions/dataCollectionActions';
import { DATA_COLLECTION_PROCESS } from 'constants/dataCollection';
import { isBrokerLogin } from 'lib/utils/common';

const DataCollectionLanding = ({
  pushSpinner,
  popSpinner,
  primaryApplicant,
  isSpinnerLoading,
  selectedProcesses,
  postConsentRequest,
  applicationId,
  verificationExchange,
  history,
  subStatuses,
  getVerificationExchange,
  setLogoutStatus,
}) => {
  const { oktaAuth } = useOktaAuth();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (applicationId && !isBrokerLogin) {
      getVerificationExchange({ applicationId });
    }
  }, [applicationId, getVerificationExchange]);

  const logout = async () => {
    logEvent(EVENTS.LOG_OUT);
    pushSpinner('LOGOUT');
    setLogoutStatus({ status: true });
    await signOut(oktaAuth);
    popSpinner('LOGOUT');
    setLogoutStatus({ status: false });
    LocalStorageProxy.clearAll();
    sessionStorage.clear();

    window.location.assign(window.LM_CONFIG.LM_DOMAIN);
  };

  const onPostConsentRequest = (status) => {
    postConsentRequest({ applicationId, status });
  };

  const hasVerificationExchange =
    subStatuses &&
    !!subStatuses.find(
      (subStatus) =>
        subStatus.process === DATA_COLLECTION_PROCESS.VerificationExchange,
    );

  return (
    <>
      <ModalProvider>
        <View showMenu={false} showHelpFooter>
          <Spinner loading={isSpinnerLoading}>
            <DataCollectionLandingContent
              name={primaryApplicant?.preferredName}
              onLogoutClick={logout}
              selectedProcesses={selectedProcesses}
              onPostConsentRequest={onPostConsentRequest}
              verificationExchange={verificationExchange}
              isSpinnerLoading={isSpinnerLoading}
              history={history}
              hasVerificationExchange={hasVerificationExchange}
            />
          </Spinner>
        </View>
      </ModalProvider>
    </>
  );
};

DataCollectionLanding.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  primaryApplicant: PropTypes.object,
  pushSpinner: PropTypes.func,
  popSpinner: PropTypes.func,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
  history: PropTypes.object,
  verificationExchange: PropTypes.object,
  applicationId: PropTypes.number,
  subStatuses: PropTypes.arrayOf(PropTypes.object),
  setLogoutStatus: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  requestsUIState: UISelectors.requests(state),
  primaryApplicant: clientSelectors.primaryApplicant(state),
  selectedProcesses: dataCollectionSelectors.selectedProcesses(state),
  verificationExchange: dataCollectionSelectors.verificationExchange(state),
  applicationId: applicationSelectors.getApplicationId(state),
  subStatuses: dataCollectionSelectors.subStatuses(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSpinner: UIActions.pushSpinner,
      popSpinner: UIActions.popSpinner,
      setLogoutStatus: UIActions.setLogoutStatus,
      postConsentRequest: dataCollectionActions.postVerificationExchange,
      getVerificationExchange: dataCollectionActions.getVerificationExchange,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DataCollectionLanding),
);
