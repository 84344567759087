import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import { BUYER_SCENARIOS } from 'constants/loanScenariosUI';
import { afterBuyerScenario } from 'lib/pathHelper';

import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import ButtonList from 'components/ButtonList/ButtonList';
import View from 'components/View/View';
import Spinner from 'components/Spinner/Spinner';

export class BuyerScenariosComponent extends Component {
  static propTypes = {
    setScenarioName: PropTypes.func.isRequired,
    goToPathWithAnimation: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  buyerScenariosAction = (value) => {
    const { setScenarioName, goToPathWithAnimation } = this.props;
    setScenarioName(value);
    goToPathWithAnimation(afterBuyerScenario(value));
  };

  render() {
    return (
      <View>
        {/* to make spinner loading */}
        <Spinner loading='true'>
          <ContentsWrapper
            id='BuyerScenarios'
            title='What are you looking to do?'
          >
            <Question
              id='BuyerScenario'
              formCompleted
              direction='column'
              className='alignLeft'
            >
              <ButtonList
                items={BUYER_SCENARIOS}
                action={this.buyerScenariosAction}
              />
            </Question>
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ scenario: state.scenario });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setScenarioName: scenarioActions.setScenarioName,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuyerScenariosComponent);
