import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UISelectors from 'selectors/UISelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as privacyPolicySelectors from 'selectors/privacyPolicySelectors';

import UIActions from 'actions/UIActions';
import loanApplicationActions from 'actions/loanApplicationActions';
import privacyPolicyActions from 'actions/privacyPolicyActions';

import { APPLY_BASE_PATH, NEXT_STEPS_PATH } from 'shared/constants/paths';
import { fromPrivacyPolicyToNextPath } from 'lib/pathHelper';

import Spinner from 'components/Spinner/Spinner';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import ScrollableContent from 'components/ScrollableContent/ScrollableContent';
import View from 'components/View/View';
import Button from 'components/Button/Button';
import BackgroundColorWrapper from 'components/BackgroundColorWrapper/BackgroundColorWrapper';

const PrivacyPolicy = (props) => {
  const {
    isSpinnerLoading,
    goToPathWithAnimation,
    nextPath,
    nextSequence,
    privacyPolicyConsent,
    updateLoanApplication,
    executeToken,
    currentFamilyId,
    currentClientId,
    application: { isPrivacyPolicyOnly, hasSignedPrivacyPolicy, id },
    fetchingUserInfo,
    setPageError,
    updatingLoanApp,
    isOneTimePrivacyPolicy,
    executingToken,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!fetchingUserInfo && !privacyPolicyConsent && !isSpinnerLoading) {
      setPageError({ status: 506 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingUserInfo, privacyPolicyConsent]);

  useEffect(() => {
    if (!updatingLoanApp && hasSignedPrivacyPolicy) {
      const path =
        nextPath.length > 0
          ? nextPath[nextPath.length - 1]
          : `${APPLY_BASE_PATH}/${id}`;
      const animation =
        isPrivacyPolicyOnly || isOneTimePrivacyPolicy
          ? fromPrivacyPolicyToNextPath(NEXT_STEPS_PATH)
          : fromPrivacyPolicyToNextPath(path, nextSequence);
      goToPathWithAnimation(animation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingLoanApp, hasSignedPrivacyPolicy]);

  const handleSubmit = () => {
    if (isOneTimePrivacyPolicy) {
      executeToken();
    } else {
      updateLoanApplication({
        currentFamilyId,
        currentClientId,
        hasSignedPrivacyPolicy: true,
      });
    }
  };

  return (
    <BackgroundColorWrapper>
      <View inverse showMenu={false}>
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper
            id='privacyPolicy'
            title='Your Privacy'
            className='halfwidth'
            inverseHeader
            centeredTitle
            subtitles={[
              "We'll collect information about you during the process. Please take a read of our privacy policy",
            ]}
          >
            <br />
            <ScrollableContent content={privacyPolicyConsent} />
            <Button
              disabled={
                !privacyPolicyConsent || updatingLoanApp || executingToken
              }
              onClick={handleSubmit}
              theme='buttonNext'
              className='brandColor__font--hover'
            >
              I agree
            </Button>
          </ContentsWrapper>
        </Spinner>
      </View>
    </BackgroundColorWrapper>
  );
};

PrivacyPolicy.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  nextPath: PropTypes.arrayOf(PropTypes.string),
  nextSequence: PropTypes.arrayOf(PropTypes.string),
  goToPathWithAnimation: PropTypes.func,
  privacyPolicyConsent: PropTypes.string,
  updateLoanApplication: PropTypes.func,
  currentFamilyId: PropTypes.number,
  currentClientId: PropTypes.number,
  application: PropTypes.object,
  fetchingUserInfo: PropTypes.bool,
  setPageError: PropTypes.func,
  updatingLoanApp: PropTypes.bool,
  isOneTimePrivacyPolicy: PropTypes.bool,
  executingToken: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  application: applicationSelectors.workingApplication(state),
  scenario: state.scenario,
  currentFamilyId: clientSelectors.primaryApplicantContactId(state),
  currentClientId: clientSelectors.getPrimaryApplicantId(state),
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  nextPath: UISelectors.nextPath(state),
  nextSequence: UISelectors.nextSequence(state),
  privacyPolicyConsent: state.advisor.privacyPolicyConsent,
  fetchingUserInfo: UISelectors.fetchingUserInfo(state),
  updatingLoanApp: UISelectors.updatingLoanApp(state),
  isOneTimePrivacyPolicy: UISelectors.isOneTimePrivacyPolicy(state),
  token: privacyPolicySelectors.token(state),
  executingToken: privacyPolicySelectors.executingToken(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
      updateLoanApplication: loanApplicationActions.updateLoanApplication,
      executeToken: privacyPolicyActions.executeToken,
      setPageError: UIActions.setPageError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
