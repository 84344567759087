import BulbIcon from 'assets/icons/bulb.svg';
import DollarIcon from 'assets/icons/dollar.svg';
import EnergyIcon from 'assets/icons/energy.svg';
import HeartIcon from 'assets/icons/heart.svg';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { DataCollectionPageSection } from 'components/DataCollectionPageSection/DataCollectionPageSection';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import SVGInline from 'react-svg-inline';
import styles from './DataCollectionLandingContent.css';
import { DATA_COLLECTION_PROCESS } from 'constants/dataCollection';
import {
  DATA_COLLECTION_BANK_STATEMENT_PATH,
  DATA_COLLECTION_ID_VERIFICATION_PATH,
} from 'shared/constants/paths';
import { useModal } from 'contexts/ModalContext';
import { Modal } from 'components/Modal/Modal';
import DataCollectioConsentModal from './DataCollectioConsentModal';
import { featureFlags } from 'lib/rollout';

const initialState = {
  agree: false,
  disagree: false,
};
const WhatToExpect = ({
  selectedProcesses,
  onPostConsentRequest,
  verificationExchange,
  isSpinnerLoading,
  history,
  hasVerificationExchange,
}) => {
  const [processConsentStatus, setProcess] = useState(initialState);
  const isIdVerifyProcessSelected = selectedProcesses.includes(
    DATA_COLLECTION_PROCESS.Scantek,
  );
  const nextPagePath = isIdVerifyProcessSelected
    ? DATA_COLLECTION_ID_VERIFICATION_PATH
    : DATA_COLLECTION_BANK_STATEMENT_PATH;
  const isBankProcessSelected = selectedProcesses.includes(
    DATA_COLLECTION_PROCESS.Illion,
  );
  const hasConsent = verificationExchange.CreatedByUserId;
  const { isModalShown, hideModal, modalConfig, showModal } = useModal();

  useEffect(() => {
    if (
      (processConsentStatus.agree || processConsentStatus.disagree) &&
      !isSpinnerLoading
    ) {
      hideModal();
      setProcess(initialState);
      history.push(nextPagePath);
    }
  }, [
    history,
    processConsentStatus,
    isSpinnerLoading,
    nextPagePath,
    hideModal,
  ]);

  const handleCloseModal = () => {
    setProcess({ disagree: true });
    onPostConsentRequest(false);
  };

  const handleShowModal = () => {
    showModal({
      closeOnOverlay: false,
      theme: 'fullWidth',
      isCloseButtonVisible: false,
    });
  };

  const onHandleAcceptTermsAndConditions = () => {
    setProcess({ agree: true });
    onPostConsentRequest(true);
  };

  const getStartedButton =
    !hasConsent && hasVerificationExchange ? (
      <Button
        onClick={handleShowModal}
        theme='buttonNext'
        className={styles.blockBtn}
      >
        Continue
      </Button>
    ) : (
      <Button url={nextPagePath} theme='buttonNext' className={styles.blockBtn}>
        Continue
      </Button>
    );

  const isScantekBiometricsEnabled = featureFlags[
    'mycrm-api.consumer.data-collection-scantek-biometrics'
  ].isEnabled();

  const contentForBiometrics = `You'll need two ID documents, and will be asked to take a selfie to
      verify identity. Keep your Australian drivers licence & Medicare card or
      passport nearby`;
  const contentNormalProcess = `You'll need two forms of ID documents, so keep your Australian
      drivers licence & Medicare card or passport nearby`;
  const identityContent = isScantekBiometricsEnabled
    ? contentForBiometrics
    : contentNormalProcess;
  return (
    <DataCollectionPageSection id='dataCollectionLanding'>
      <h1>What to expect</h1>
      <ol className={classNames(styles.nextStepContainer, styles.whatToExpect)}>
        {isIdVerifyProcessSelected && (
          <li className={styles.removerSpacer}>
            <div className={styles.iconContainer}>
              <SVGInline svg={EnergyIcon} />
              <div className={styles.line} aria-hidden='true' />
            </div>
            <div>
              <h3>Verify your identity</h3>
              <p className={styles.lighterText}>{identityContent}</p>
            </div>
          </li>
        )}
        {isBankProcessSelected && (
          <li className={styles.removerSpacer}>
            <div className={styles.iconContainer}>
              <SVGInline svg={DollarIcon} />
              <div className={styles.line} aria-hidden='true' />
            </div>
            <div>
              <h3>Connect to your banks</h3>
              <p className={styles.lighterText}>
                Keep your online banking details handy so that you can securely
                provide your expenses
              </p>
            </div>
          </li>
        )}
        <li className={styles.removerSpacer}>
          <div className={styles.iconContainer}>
            <SVGInline svg={HeartIcon} />
          </div>
          <div>
            <h3>Complete your profile</h3>
            <p className={styles.lighterText}>
              Fill in your profile so that your broker can review your details
              and find the right loan for you
            </p>
          </div>
        </li>
      </ol>

      <div className={styles.alert}>
        <SVGInline svg={BulbIcon} />
        <p>
          We take data security seriously and use advanced encryption to keep
          your data safe
        </p>
      </div>

      <div className={styles.buttonGroup}>{getStartedButton}</div>

      {isModalShown && (
        <Modal
          customClassName={styles.termsAndConditionModal}
          {...modalConfig}
          hideModal={handleCloseModal}
        >
          <DataCollectioConsentModal
            onPostConsentRequest={onHandleAcceptTermsAndConditions}
            onCloseModal={handleCloseModal}
            isSpinnerLoading={isSpinnerLoading}
            processConsentStatus={processConsentStatus}
            nextPagePath={nextPagePath}
          />
        </Modal>
      )}
    </DataCollectionPageSection>
  );
};

WhatToExpect.propTypes = {
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
  verificationExchange: PropTypes.object,
  isSpinnerLoading: PropTypes.bool,
  history: PropTypes.object,
  hasVerificationExchange: PropTypes.bool,
};

export default WhatToExpect;
